<template>
    <div class="app">
        <router-view></router-view>
    </div>
</template>
<script>
export default {
  name: 'app',
  methods: {
    async fetchTwoNumbers() {
      try {
        const res = await axios.post('/twod_numbers', {
          limit: 'all',
        });
        this.$store.commit('setTwodnumbers', res.data.data);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchThreeNumbers() {
      try {
        const res = await axios.post('/threed_numbers', {
          limit: 'all',
        }, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });

        this.$store.commit('setThreednumbers', res.data.data);
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.fetchTwoNumbers();
    this.fetchThreeNumbers();
    // chrome.runtime.sendMessage(message, () => {
    //   // Empty callback function to ignore the response
    // });
    // localStorage.setItem('language', 'mm');
    // this.$store.commit('setLanguage', 'mm');
  },
};
</script>
